import React, { createContext, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './pages/main.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tickets from './pages/ticket.js';
import Bron from './pages/bron.js';
import Payment from './pages/payment.js';

// Создаем контекст для header
export const HeaderContext = createContext(null);

function extractHeaderFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const header = urlParams.get('a');
  if (header) {
    // Сохраняем header в localStorage
    localStorage.setItem('header', header);
    // Удаляем параметр из URL
    urlParams.delete('a');
    const newUrl = `${window.location.origin}${window.location.pathname}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
    window.history.replaceState(null, '', newUrl);
  }
  return header || localStorage.getItem('header');
}

// Компонент с сообщением
function DesktopDevelopmentNotice() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'black',
      color: 'red',
      fontSize: '24px',
      fontWeight: 'bold',
    }}>
      Идет разработка desktop сайта
    </div>
  );
}

function App() {
  const header = extractHeaderFromUrl(); // Извлекаем header

  // Проверка, что это ПК (ширина экрана больше, например, 1024px)
  var isDesktop = window.innerWidth > 1024;
  var isSBP = window.location.hostname.includes("sbp")
  if (isSBP) {
    document.location.href = document.location.href+"payment"
    return 
  }
  return (
    <HeaderContext.Provider value={header}>
      {isDesktop ? (
        <DesktopDevelopmentNotice />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/bron" element={<Bron />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </BrowserRouter>
      )}
    </HeaderContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
