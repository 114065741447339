import React, { useRef, useEffect, useState } from 'react';
import './css/ticket.css';
import { Link } from 'react-router-dom';

function Tickets() {
    const canvasRef = useRef(null);
    const [total, setTotal] = useState(0);
    const [selectedSeats, setSelectedSeats] = useState(new Set());
    const [scale, setScale] = useState(0.65);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [scrollPosition, setScrollPosition] = useState({ x: -30, y: 70 });

    const [lastTouchDistance, setLastTouchDistance] = useState(null);
    // Настраиваемые отступы для границы перемещения
    const boundaryPaddingX = 100; // отступ по горизонтали
    const boundaryPaddingY = 100; // отступ по вертикали

    const seatColors = {
        free: '#1e90ff',
        taken: '#d3d3d3',
        selected: '#104e8b', // Цвет для выбранного места
    };

    const seatingData = [
        { table: 2, seats: 2, status: ['taken', 'taken'] },
        { table: 3, seats: 2, status: ['taken', 'taken'] },
        { table: 4, seats: 2, status: ['free', 'taken'] },
        { table: 5, seats: 2, status: ['taken', 'taken'] },
        { table: 6, seats: 2, status: ['free', 'taken'] },
        { table: 7, seats: 2, status: ['taken', 'taken'] },
        { table: 8, seats: 2, status: ['taken', 'taken'] },
        { table: 9, seats: 2, status: ['taken', 'taken'] },
        { table: 10, seats: 2, status: ['free', 'free'] },
        { table: 11, seats: 2, status: ['free', 'taken'] },
        { table: 12, seats: 2, status: ['taken', 'taken'] },
        { table: 13, seats: 2, status: ['free', 'taken'] },
        { table: 14, seats: 2, status: ['free', 'taken'] },
        { table: 15, seats: 2, status: ['taken', 'taken'] },
        { table: 16, seats: 2, status: ['free', 'free'] },
    ];

    useEffect(() => {
        // Сохраняем `total` в localStorage при изменении
        localStorage.setItem('total', total);
    }, [total]);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const dpr = window.devicePixelRatio || 1;
        const logicalWidth = window.innerWidth;
        const logicalHeight = window.innerHeight;
    
        // Устанавливаем размеры с учетом devicePixelRatio
        canvas.width = logicalWidth * dpr;
        canvas.height = logicalHeight * dpr;
        canvas.style.width = `${logicalWidth}px`;
        canvas.style.height = `${logicalHeight}px`;
    
        // Масштабируем canvas для корректного отображения без пикселизации
        ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
    
        // Установка фона на весь холст
        ctx.fillStyle = '#f0f0f0';
        ctx.fillRect(0, 0, logicalWidth, logicalHeight);
    
        ctx.save();
        ctx.translate(scrollPosition.x, scrollPosition.y);
        ctx.scale(scale, scale);
    
        drawSeats(ctx);
        drawExtras(ctx);
    
        ctx.restore();
    }, [selectedSeats, scale, scrollPosition]);
    
    
    const roundRect = (ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    };
   // Вызовите roundRect вместо fillRect и strokeRect в drawSeats
const drawSeats = (ctx) => {
    const seatSize = 40;
    const seatMargin = -10;
    const tableWidth = 50; // ширина стола
    const tableHeight = 50; // высота стола
    const cornerRadius = 20; // радиус закругления углов

    seatingData.forEach((table, index) => {
        let x, y;

        if (table.table === 14) {
            x = 450;
            y = 520;
        } else if (table.table === 15) {
            x = 100;
            y = 520;
        } else if (table.table === 16) {
            x = 270;
            y = 460;
        } else {
            x = 100 + (index % 3) * 100;
            y = 50 + Math.floor(index / 3) * 100;
        }

        ctx.fillStyle = '#ffffff';
        ctx.strokeStyle = '#000000';
        roundRect(ctx, x - 10, y - 10, tableWidth, tableHeight, cornerRadius);

        ctx.fillStyle = '#000000';
        ctx.font = '18px Arial';
        ctx.fillText(`${table.table}`, x + 5, y + 20);

        table.status.forEach((status, i) => {
            const seatX = x + i * (seatSize + seatMargin);
            const seatY = y - 25;
            const seatId = `${table.table}-${i + 1}`;

            const isSelected = selectedSeats.has(seatId);
            ctx.fillStyle = isSelected ? seatColors.selected : seatColors[status];
            ctx.beginPath();
            ctx.arc(seatX, seatY, isSelected ? seatSize * 0.6 : seatSize / 3, 0, Math.PI * 2);
            ctx.fill();


            if (isSelected) {
                ctx.fillStyle = '#ffffff';
                ctx.beginPath();
                ctx.arc(seatX, seatY, seatSize / 6, 0, Math.PI * 2);
                ctx.fill();
            }
        });
    });
};

const drawExtras = (ctx) => {
    const sceneX = 180; // Позиция X сцены
    const sceneY = 520; // Позиция Y сцены
    const sceneWidth = 220; // Ширина сцены
    const sceneHeight = 50; // Высота сцены
    const cornerRadius = 15; // Радиус закругления углов

    // Закруглённый блок сцены
    ctx.fillStyle = '#cccccc';
    roundRect(ctx, sceneX, sceneY, sceneWidth, sceneHeight, cornerRadius);
    ctx.fillStyle = '#000000';
    ctx.fillText('СЦЕНА', 255, 550);

    // Параметры для закруглённого блока бара
    const barX = 500; // Позиция X бара
    const barY = 50; // Позиция Y бара
    const barWidth = 50; // Ширина бара
    const barHeight = 130; // Высота бара

    // Закруглённый блок бара
    ctx.fillStyle = '#cccccc';
    roundRect(ctx, barX, barY, barWidth, barHeight, cornerRadius);
    ctx.fillStyle = '#000';
    ctx.font = '16px Arial';
    ctx.fillText('БАР', barX + 10, barY - 10);

    // Рисуем свободные места около бара
    for (let i = 0; i < 2; i++) {
        ctx.fillStyle = seatColors['taken'];
        ctx.beginPath();
        ctx.arc(barX - 25, barY + 40 + i * 50, 10, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();
    }
};



const handleCanvasClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleAdjustedX = (e.clientX - rect.left - scrollPosition.x) / scale;
    const scaleAdjustedY = (e.clientY - rect.top - scrollPosition.y) / scale;

    seatingData.forEach((table) => {
        let x, y;

        if (table.table === 14) {
            x = 450;
            y = 520;
        } else if (table.table === 15) {
            x = 100;
            y = 520;
        } else if (table.table === 16) {
            x = 270;
            y = 460;
        } else {
            x = 100 + ((table.table - 2) % 3) * 100;
            y = 50 + Math.floor((table.table - 2) / 3) * 100;
        }

        table.status.forEach((status, j) => {
            const seatX = x + j * 30;
            const seatY = y - 25;
            const isSeatClicked =
                scaleAdjustedX >= seatX - 10 &&
                scaleAdjustedX <= seatX + 10 &&
                scaleAdjustedY >= seatY - 10 &&
                scaleAdjustedY <= seatY + 10;

            if (isSeatClicked && status === 'free') {
                handleSeatClick(`${table.table}-${j + 1}`);
            }
        });
    });
};


    const handleSeatClick = (seatId) => {
        const tableNumber = parseInt(seatId.split('-')[0], 10); // Получаем номер стола из seatId
    
        let price = 2990; // Базовая цена
        if (tableNumber >= 1 && tableNumber <= 10) {
            price = 2490;
        } else if (tableNumber >= 11 && tableNumber <= 13) {
            price = 2990;
        } else if (tableNumber >= 14 && tableNumber <= 15) {
            price = 3490;
        } else if (tableNumber === 16) {
            price = 5490;
        }
    
        // Если место уже выбрано, снимаем выбор и вычитаем цену из итоговой суммы
        if (selectedSeats.has(seatId)) {
            setSelectedSeats(prev => {
                const newSelection = new Set(prev);
                newSelection.delete(seatId);
                return newSelection;
            });
            setTotal(prevTotal => prevTotal - price);
        } else {
            // Если место свободно и не выбрано, добавляем его к выбранным и прибавляем цену
            setSelectedSeats(prev => new Set(prev).add(seatId));
            setTotal(prevTotal => prevTotal + price);
        }
    };
    
const handleZoomIn = () => setScale((prevScale) => Math.round((prevScale + 0.1) * 10) / 10);
const handleZoomOut = () => setScale((prevScale) => Math.round((prevScale - 0.1) * 10) / 10);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const xDiff = e.clientX - startPosition.x;
        const yDiff = e.clientY - startPosition.y;
    
        setScrollPosition((prevPosition) => ({
            x: Math.max(Math.min(prevPosition.x + xDiff, 0), -(canvasRef.current.width / scale - window.innerWidth)),
            y: Math.max(Math.min(prevPosition.y + yDiff, 0), -(canvasRef.current.height / scale - window.innerHeight)),
        }));
    
        setStartPosition({ x: e.clientX, y: e.clientY });
    };
    
    
    const handleMouseUp = () => setIsDragging(false);

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            setIsDragging(true);
            setStartPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        } else if (e.touches.length === 2) {
            const distance = getTouchDistance(e);
            setLastTouchDistance(distance);
            setIsDragging(false);
        }
    };

    const handleTouchEnd = () => {
        setLastTouchDistance(null);
        setIsDragging(false);
    };

    const handleTouchMove = (e) => {
        if (e.touches.length === 2 && lastTouchDistance) {
            const distance = getTouchDistance(e);
            const scaleChange = distance / lastTouchDistance;
            setScale((prevScale) => Math.min(Math.max(prevScale * scaleChange, 0.5), 2));
            setLastTouchDistance(distance);
        } else if (e.touches.length === 1 && isDragging) {
            const touch = e.touches[0];
            const xDiff = touch.clientX - startPosition.x;
            const yDiff = touch.clientY - startPosition.y;
    
            // Вычисляем максимальные и минимальные значения прокрутки
            const maxX = boundaryPaddingX;
            const minX = -(canvasRef.current.width / scale - window.innerWidth + boundaryPaddingX);
            const maxY = boundaryPaddingY;
            const minY = -(canvasRef.current.height / scale - window.innerHeight + boundaryPaddingY);
    
            setScrollPosition((prevPosition) => ({
                x: Math.max(Math.min(prevPosition.x + xDiff, maxX), minX),
                y: Math.max(Math.min(prevPosition.y + yDiff, maxY), minY),
            }));
    
            setStartPosition({ x: touch.clientX, y: touch.clientY });
        }
    };
    
    

    const getTouchDistance = (e) => {
        const [touch1, touch2] = e.touches;
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };


    return (
        <div className="tickets-container">
            <div className="controls">
                <span id="total">Итого: {total} ₽</span>
                
            </div>

            <canvas
                ref={canvasRef}
                width={800}
                height={600}
                className="seat-map"
                onClick={handleCanvasClick}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}

            />

            <div className="info">
                <div className="info-item available">Доступно</div>
                <div className="info-item sold">Занято</div>
                <div className="info-item reserved">Выбрано</div>
            </div>

            <div className="info2">
    <Link to="/bron" style={{ textDecoration: 'none' }}>
        {selectedSeats.size > 0 && <button>Забронировать</button>}
    </Link>
</div>

        </div>
    );
}

export default Tickets;
