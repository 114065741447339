import './css/main.css';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logotip from './img/logo.png';
import card1 from './img/cards/img1.png';
import card2 from './img/cards/img2.png';
import { HeaderContext } from '../index.js';
import card4 from './img/cards/img4.png';
import axios from 'axios';
import eda from './img/food.png';
import com1 from './img/comics/1.png';
import com2 from './img/comics/2.png';
import com3 from './img/comics/3.png';
import com4 from './img/comics/4.png';
import com5 from './img/comics/5.png';
import com6 from './img/comics/6.png';
import com7 from './img/comics/7.png';
import com8 from './img/comics/8.png';
import icic from './img/icic.png'
function Main() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [ev, setEv] = useState([]);
  const header = useContext(HeaderContext);
  console.log(header, "header ")
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  useEffect(() => {
    const fetchEventData = async () => {
   
      try {


        const response = await fetch('/api/event_data', {
          headers: { 'Auth': header },
        });
        const data = await response.json();
        setEvents(data.events || []);
        setEv(data || [])
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);

  const handleBuyOrReserve = () => {
    navigate('/tickets');
  };

  useEffect(() => {
    const handleScroll = (event) => {
      const pageHeight = window.innerHeight;
      const scrollY = window.scrollY;

      if (event.deltaY > 0 && scrollY < pageHeight) {
        window.scrollTo({ top: pageHeight, behavior: 'smooth' });
      } else if (event.deltaY < 0 && scrollY >= pageHeight) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      document.body.style.overflowY = 'auto';
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div className="main-container">
      <nav className="navbar sticky-top bg-dark text-white d-flex justify-content-between align-items-center">
        <button className="hamburger" onClick={() => setMenuOpen(!isMenuOpen)}>
          ☰
        </button>
        <span className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <button onClick={() => scrollToSection('afisha')}>Афиша</button>
          <button onClick={() => scrollToSection('guests')}>Гости</button>
          <button onClick={() => scrollToSection('contacts')}>Контакты</button>
        </span>
        <button className={`buy buy3 mt-3 ${isMenuOpen ? 'open' : ''}`} onClick={() => scrollToSection('afisha')}>КУПИТЬ БИЛЕТ</button>
      </nav>

      <section className="screen screen-1 bg-dark d-flex flex-column justify-content-center align-items-center">
        <img src={logotip} className="logotip col-7 col-sm-4 col-md-5 col-lg-3 mb-3" alt="Логотип" />
        <p className="data1 text-center">ВС-ЧТ 14:00 - 02:00 | ПТ-СБ 14:00 - 06:00</p>
        <button className="buy mt-3" onClick={() => scrollToSection('afisha')}>КУПИТЬ БИЛЕТ</button>
        <img src={icic} className="strelka" width={50}></img>
      </section>

      <section id="afisha" className="screen screen-2 d-flex flex-column align-items-center">
        <p className="display-4 text-white text-center afish">АФИША</p>
        <div className="card-group justify-content-center w-100">
        {events.map((event, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-3 mb-4 d-flex flex-column align-items-center">
              <div className="card">
                <p className="data">{event.date || 'Дата не указана'}</p>
                <img
                  src={event.image_url || card1}
                  className="card-img-top"
                  alt={event.name || 'Без названия'}
                  onError={(e) => (e.target.src = card1)}
                />
                <button className="buy buy2 mt-1" onClick={handleBuyOrReserve}>ЗАБРОНИРОВАТЬ</button>
                <p className="opis mt-1">{event.name || 'Без названия'}</p>
              </div>
            </div>
          ))}
          
        </div>
      </section>

      <section id="guests" className='screen screen-3 d-flex flex-column align-items-center'>
        <img src={eda} alt="Еда" />
      </section>

      <section id='quests' className='screen screen-4 f-flex flex-column align-tems-center'>
        <p className='comicsp'>КОМИКИ, ГОСТИ И ДРУЗЬЯ КВАРТИРЫ</p>
        <div className="col-12 col-sm-6 col-md-2 col-lg-12 mb-0 comecs">
          <img src={com1} className="comec" alt="Карточка" />
          <img src={com2} className="comec" alt="Карточка" />
          <img src={com3} className="comec" alt="Карточка" />
          <img src={com4} className="comec" alt="Карточка" />
          <img src={com5} className="comec" alt="Карточка" />
          <img src={com6} className="comec" alt="Карточка" />
          <img src={com7} className="comec" alt="Карточка" />
          <img src={com8} className="comec" alt="Карточка" />
        </div>
      </section>

      <section id='contacts' className='screen screen-4 screen-5 f-flex flex-column'>
        <p className='contactsh1'>НАШИ КОНТАКТЫ</p>
        <p className='adress'>Адрес: {ev.place}</p>
        <p className='adress'>ООО "КОМЕДИ КЛАБ ПРОДАКШН" ИНН 7725593209, ОГРН 1067761746106</p>
        <span className='d-flex ddd'>
          <p className='cmd'>© 2024 Стэнд Ап «КОМЕДИ КЛАБ ПРОДАКШН». Все права защищены.</p>
          <span className='dddd'>
            <a className='cmd' href='https://standup.standclub.org/policy.pdf'>Политика конфиденциальности</a>
            <a className='cmd' href='https://standup.standclub.org/policy.pdf'>Правила поведения</a>
          </span>
        </span>
      </section>
    </div>
  );
}

export default Main;
