import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { HeaderContext } from '../index.js';
import './css/main.css';

function Bron() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const header = useContext(HeaderContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const total = localStorage.getItem('total') || 0;
  const menuItems = [
    {
      title: 'Фирменные Бургеры',
      description: 'Котлеты из сочной говядины, томаты, листья салата с добавлением авторских соусов. Также есть позиции бургеров для вегетарианцев.',
      priceRange: '₽450',
      image: 'burger.png', // Replace with actual image path
    },
    {
      title: 'Фирменная Подборка Чая',
      description: 'Авторские свежесваренные чаи из свежих ягод, фруктов и специй. Также есть линейка классических и китайских чаев.',
      priceRange: '₽590',
      image: 'tea.png',
    },
    {
      title: 'Завтраки All Day',
      description: 'Домашние сырники с джемом и сметаной, бельгийские вафли / Сладкие вафли.',
      priceRange: '₽350',
      image: 'breakfast.png',
    },
    {
      title: 'Коктейли',
      description: 'Эксклюзивная линейка авторских коктейлей. Также есть классические позиции. Есть готовые сеты для компаний.',
      priceRange: '₽250',
      image: 'cocktail.png',
    },
    {
      title: 'Паста / Wok / Удон',
      description: 'Классическая итальянская паста / Паназиатские wok и удон с авторским сливочным соусом и топингом на выбор.',
      priceRange: '₽350',
      image: 'pasta.png',
    },
    {
      title: 'Домашние Десерты',
      description: 'Штрудели, Наполеон в авторской подаче, Чизкейк.',
      priceRange: '₽310',
      image: 'dessert.png',
    },
  ];

  const handleApiRequest = async () => {
    if (!name || !phone) {
      alert("Пожалуйста, заполните все поля.");
      return;
    }
  
    const totalAmount = total; // Пример расчета суммы, замените на свою логику
    const payload = {
      fio: name,
      phone: phone.replace(/\D/g, ''), // Убираем символы из номера телефона
      amount_rub: totalAmount,
    };
  
    try {
      const response = await fetch('/api/get_pay_link', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Auth': header},
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`Ошибка: ${response.status}`);
      }
  
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url; // Переход на страницу оплаты
      } else {
        alert("Не удалось получить ссылку на оплату.");
      }
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      alert("Произошла ошибка. Попробуйте позже.");
    }
  };
  
  const handleItemSelection = (title, price) => {
    setSelectedItems((prev) =>
      prev.includes(title) ? prev.filter((item) => item !== title) : [...prev, title]
    );
  
    // Парсим текущую сумму из localStorage или устанавливаем 0
    const currentTotal = parseFloat(localStorage.getItem('total')) || 0;
  
    // Убираем знак валюты и преобразуем цену в число
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
  
    // Если блюдо выбрано — прибавляем цену, если убрано — вычитаем
    const isAdding = !selectedItems.includes(title);
    const newTotal = isAdding ? currentTotal + numericPrice : currentTotal - numericPrice;
  
    // Сохраняем новую сумму в localStorage, убирая лишние нули
    localStorage.setItem('total', Math.round(newTotal));
  };
  

  const handleNext = () => {
    if (name && phone) {
      navigate('/payment');
    } else {
      alert("Пожалуйста, заполните все поля.");
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="sss">Меню</h2>
        <div className="lala col-12 col-sm-6 col-md-2 col-lg-12 mb-0">
        {menuItems.map((item, index) => (
  <div
    key={index}
    onClick={() => handleItemSelection(item.title, item.priceRange)}
    style={{ cursor: 'pointer' }}
  >
    <div className={`cardd ${selectedItems.includes(item.title) ? 'border-primary' : ''}`}>
      <h5 className="card-title">{item.title}</h5>
      <img src={item.image} alt={item.title} className={`card-img-topp ${selectedItems.includes(item.title) ? 'selected' : ''}`} />
      <div className="card-body">
        <p className="card-text">{item.description}</p>
        <p className="text-mutedd">{item.priceRange}</p>
      </div>
    </div>
  </div>
))}

        </div>
        
        {showForm && (
          <>
            <div className="modall-backdrop" onClick={() => setShowForm(false)}></div>
            <div className="modal-container">
              <form className="modal-content">
                <h5 className='sss ssss'>Бронирование</h5>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Имя</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder='ФИО'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Номер телефона</label>
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    className="form-control"
                    id="phone"
                    placeholder='Ваш номер телефона'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <button type="button" onClick={handleApiRequest} className="brondal my-3">Далее</button>
                <button type="button" onClick={() => setShowForm(false)} className="close-button">Закрыть</button>
              </form>
            </div>
          </>
        )}
        <span className='buyspan col-12 col-sm-6 col-md-2 col-lg-12 mb-0'>
          <button onClick={() => setShowForm(true)} className="my-5 buy buybuy">Забронировать</button>
        </span>
      </div>
    </>
  );
}

export default Bron;
